import { React, useState, useRef } from 'react'
import './payment.css'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux'
import { orderPayment } from '../actions/orderAction';
import { ClearCart } from '../actions/cartAction';
import { useNavigate } from 'react-router-dom';
const host = process.env.REACT_APP_API_HOST || "http://localhost:5000";



const CURRENT_YEAR = new Date().getFullYear();
const CURRENT_MONTH = new Date().getMonth();
let MONTHS = {},
    YEARS = [CURRENT_YEAR];
for (let i = 1; i <= 12; i++) {
    MONTHS[i] = i.toString().length === 1 ? `0${i}` : i.toString();
    YEARS.push(YEARS[0] + i);
}

const Payment = () => {
    const redirect = useNavigate();
    const dispatch = useDispatch();
    const order = useSelector((state) => state.Order.order)
     
    const [sliderLocation, setSliderLocation] = useState("");
    const [cardNumber, setCardNumber] = useState("");
    const [cardName, setCardName] = useState("");
    const [cardMonth, setCardMonth] = useState(0);
    const [cardYear, setCardYear] = useState(0);
    const [cardCvv, setCardCvv] = useState("");
    const [cardType, setCardType] = useState("visa");
    const [toggleMonth, setToggleMonth] = useState(true);
    const [toggleYear, setToggleYear] = useState(true);
    const [showCard, setShowCard] = useState(false);
    const [cardFlipped, setCardFlipped] = useState(false);

    var cardNumberRef = useRef(null);
    var cardNameRef = useRef(null);
    var cardMonthRef = useRef(null);
    var cardYearRef = useRef(null);



    const getCardType = number => {
        let re = new RegExp("^4");
        if (number.match(re) != null) return "visa";
        re = new RegExp("^(34|37)");
        if (number.match(re) != null) return "amex";
        re = new RegExp("^5[1-5]");
        if (number.match(re) != null) return "mastercard";
        re = new RegExp("^6011");
        if (number.match(re) != null) return "discover";
        return "visa";
    };

    const handleChange = (event, type) => {
        let { value } = event.target;
        if (type === "cardNumber") {
            value = value.replace(/ /gi, "");
            if (isNaN(value)) {
                return;
            } else {
                const cardType = getCardType(value);
                setCardNumber(value);
                setCardType(cardType);
            }
        } else if (type === "cardName") {
            var regName = /^[a-zA-Z\s]*$/;
            if (!regName.test(value)) {
                return;
            } else {
                setCardName(value);
            }
        } else if (type === "cardMonth") {
            value = Number(value);
            setCardMonth(value);
            setToggleMonth(prevState => !prevState);
        } else if (type === "cardYear") {
            value = Number(value);
            if (value === CURRENT_YEAR && cardMonth <= CURRENT_MONTH) {
                setCardYear(value);
                setCardMonth(0);
                setToggleYear(prevState => !prevState);
                setToggleMonth(prevState => !prevState);
            } else {
                setCardYear(value);
                setToggleYear(prevState => !prevState);
            }
        } else if (type === "cardCvv") {
            value = value.replace(/ /gi, "");
            if (isNaN(value)) {
                return;
            } else {
                setCardCvv(value);
            }
        }
    };

    const canSubmit = () => {
        return (
            cardNumber.length === 16 &&
            cardName.length > 4 &&
            cardCvv.length === 3 &&
            cardMonth !== 0 &&
            cardYear !== 0
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const data = {
            number: cardNumber,
            exp_month: cardMonth,
            exp_year: cardYear,
            cvc: cardCvv,
            email: order.billingInfo.email,
            amount: order.totalPrice
        }
        const config = {
            headers: {
                'Content-Type': 'application/json',
            }
        }
        axios.post(`${host}/payment`, data, config)
            .then(res => {
                dispatch(orderPayment(order))
                dispatch(ClearCart())
                redirect('/thank-you')
            })
    };
    const moveSlider = (event, position) => {
        position = ["year", "month"].includes(position) ? "expiration" : position;
        setSliderLocation(position);
    };

    let displayNumber = [];
    for (let i = 0; i < 16; i++) {
        let displayDigit = "#";
        if (typeof cardNumber[i] !== "undefined") {
            displayDigit = i > 3 && i < 12 ? "*" : cardNumber[i];
        }
        displayNumber.push(displayDigit);
    }

    const UserCanSubmit = !canSubmit();

    const formatCardNumber = (value) => {
        let v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
        let matches = v.match(/\d{4,16}/g);
        let match = (matches && matches[0]) || "";
        let parts = [];
        for (let i = 0, len = match.length; i < len; i += 4) {
          parts.push(match.substring(i, i + 4));
        }
        if (parts.length) {
          return parts.join(" ");
        } else {
          return value;
        }
      };

      const setFocus = (event, type) => {
        if (event.target.className.includes("year")) {
          event.stopPropagation();
        }
        if (type === "number") {
            cardNumberRef.focus();
            }
        else if (type === "name") {
            cardNameRef.focus();
        }
        else if (type === "month") {
            cardMonthRef.focus();
        }
        else if (type === "year") {
            cardYearRef.focus();
        }
        else {
            // if user click on any location remove class name on 

            setSliderLocation("");
            
        }

      };


    return (
        <>
            <div className="card-form" >
                <div className={`card credit-container ${showCard ? "show" : ""}`}>
                    <div className={`card inner ${cardFlipped ? "flipped" : ""}`}>
                        <div className="front">
                            <img
                                className="card" src='https://source.unsplash.com/collection/8497941/430x270'
                                onLoad={() => setShowCard(true)}
                                alt='credit-card'
                            />
                            <div className="card overlay" />
                            <div
                                className={`card slider ${sliderLocation.length > 0 ? `on-${sliderLocation}` : ""
                                    }`}
                            />
                            <div className="card content" style={{
                                background: 'transparent'
                            }} >
                                <div className="chip" style={{
                                    backgroundImage: 'https://res.cloudinary.com/jasuaje/image/upload/v1661352906/chip_gviivf.png'
                                }} />
                                <div className={`type ${cardType}`} />
                                <div
                                    className="number"
                                    onClick={event => setFocus(event, "number")}
                                    ref={node => (cardNumberRef = node)}
                                >
                                    {displayNumber.map((digit, index) => (
                                        <div className="digit-wrapper" key={index}>
                                            <div
                                                className={
                                                    digit === "#" ? "digit shown" : "digit hidden"
                                                }
                                            >
                                                #
                                            </div>
                                            <div
                                                className={
                                                    digit === "#" ? "digit hidden" : "digit shown"
                                                }
                                            >
                                                {digit === "#" ? "" : digit}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className='d-flex justify-content-between custom-pos'>
                                    <div
                                        className="name"
                                        onClick={event => setFocus(event, "name")}
                                        ref={node => (cardNameRef = node)}
                                    >
                                        <label htmlFor="name">Card Holder</label>
                                        <div id="name">
                                            <div
                                                className={`cplaceholder ${cardName.length > 0 ? "hidden" : "shown"
                                                    }`}
                                            >
                                                FULL NAME
                                            </div>
                                            <div className="name-container">
                                                {cardName.split("").map((char, index) => (
                                                    <div
                                                        className={`character ${/\s/.test(char) ? "space" : ""
                                                            }`}
                                                        key={index}
                                                    >
                                                        {char}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="expiration"
                                        onClick={event => setFocus(event, "month")}
                                    >
                                        <label htmlFor="expiration">Expires</label>
                                        <div id="expiration">
                                            <div
                                                className={`double-digit ${toggleMonth ? "toggle1" : "toggle2"
                                                    }`}
                                            >
                                                {cardMonth === 0 ? "MM" : `${cardMonth + 100}`.slice(-2)}
                                            </div>
                                            <div className="double-digit">/</div>
                                            <div
                                                className={`year double-digit ${toggleYear ? "toggle1" : "toggle2"
                                                    }`}
                                                onClick={event => setFocus(event, "year")}
                                            >
                                                {cardYear === 0 ? "YY" : `${cardYear}`.slice(-2)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-inputs">
                    <form onSubmit={handleSubmit}>
                        <div className="lg-input">
                            <label htmlFor="cardNumber"> Card Number</label>
                            <input
                                className="number-input"
                                id="cardNumber"
                                type="text"
                                onChange={event => handleChange(event, "cardNumber")}
                                onSelect={event => moveSlider(event, "number")}
                                value={formatCardNumber(cardNumber)}
                                ref={node => (cardNumberRef = node)}
                                maxLength="19"
                            />
                        </div>
                        <div className="lg-input">
                            <label htmlFor="cardName">Card Holder's Name</label>
                            <input
                                className="name-input"
                                id="cardName"
                                type="text"
                                onChange={event => handleChange(event, "cardName")}
                                onSelect={event => moveSlider(event, "name")}
                                ref={node => (cardNameRef = node)}
                                value={cardName}
                                maxLength="24"
                            />
                        </div>
                        <div className="med-input">
                            <label htmlFor="cardMonth">Expiration Date</label>
                            <select
                                className="month-input"
                                id="cardMonth"
                                value={cardMonth}
                                onChange={event => handleChange(event, "cardMonth")}
                                onFocus={event => moveSlider(event, "month")}
                                ref={node => (cardMonthRef = node)}
                            >
                                {" "}
                                <option value="0" disabled>
                                    Month
                                </option>
                                {Object.keys(MONTHS).map(monthKey => (
                                    <option
                                        key={monthKey}
                                        value={monthKey}
                                        disabled={
                                            cardYear === CURRENT_YEAR &&
                                            Number(monthKey) <= CURRENT_MONTH
                                        }
                                    >
                                        {MONTHS[monthKey]}
                                    </option>
                                ))}
                            </select>
                            <select
                                className="year-input"
                                id="cardYear"
                                value={cardYear}
                                onChange={event => handleChange(event, "cardYear")}
                                onFocus={event => moveSlider(event, "year")}
                                ref={node => (cardYearRef = node)}
                            >
                                {" "}
                                <option value="0" disabled>
                                    Year
                                </option>
                                {YEARS.map(year => (
                                    <option key={year} value={year}>
                                        {year}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="sm-input">
                            <label htmlFor="cardCvv">CVV</label>
                            <input
                                className="cvv-input"
                                id="cardCvv"
                                value={cardCvv}
                                onFocus= {event => moveSlider( event, "cvv")}
                                onChange={event => handleChange(event, "cardCvv")}
                                onSelect={() => setCardFlipped(true)}
                                maxLength="3"
                            />
                        </div>
                        <button
                            className={`lg-input ${UserCanSubmit ? "disabled" : ""}`}
                            disabled={UserCanSubmit}
                        >
                            Process Payment
                        </button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Payment